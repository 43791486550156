.footer {
  background-color: #f0f3f8; /* Lighter background color */
  color: #333; /* Darker text color */
  padding: 40px;
  font-family: "Helvetica Neue", sans-serif; /* Modern font stack */
}

.footer h2 {
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif; /* Specify a fallback font if needed */
}

.footer p {
  font-size: 16px; /* Larger font size for a modern look */
  line-height: 1.6; /* Slightly increased line height for readability */
  margin-bottom: 30px;
}

.footer-contact h3,
.footer-social h3 {
  font-size: 18px;
  margin-bottom: 20px;
  font-family: "Arial", sans-serif; /* Specify a fallback font if needed */
}

.footer-contact ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-contact li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.footer-contact li svg {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.footer-social .social-icons {
  display: flex;
}

.footer-social .social-icons a {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #333; /* Darker background for icons */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
  color: #fff; /* White color for icon text */
  transition: background-color 0.3s ease;
}

.footer-social .social-icons a:hover {
  background-color: #555; /* Slightly lighter background on hover */
}

.footer-bottom {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  font-family: "Arial", sans-serif; /* Specify a fallback font if needed */
}

.footer-bottom p {
  margin: 0;
}
