
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');

.map-container {
    width: 100%;
    margin: 0 auto;
    /* padding: 20px 0; */
    padding: 3vw;
    background: rgb(250, 245, 236);
    /* border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    background-color: #ffffff; */
  }
  
  .map-content {
    display: flex;
    flex-direction: column-reverse;
  }
  
  .map-text {
    font-family:  'Montserrat', sans-serif;
    text-transform: none;
    margin-bottom: 20px;
    margin-top: 3vw;
  }
  .map-details {
    font-weight: 200;
    margin-top: 2vw;
    color: rgb(119, 136, 153);
  }
  
  .map-image img {
    max-width: 100%;
    height: auto;
  }
  .map-heading {
    position: relative;
    display: inline-block;
    font-size: 28px;
    color: #333;
    margin-bottom: 2rem;
}

.map-heading::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 2px;
    background-color: rgb(149, 97, 155); 
    bottom: -10px;
    left: 0;
}

.map-tagline {
    font-family: fantasy;

}

.map-button {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  
  .map-button button {
    padding: 7px 16px;
    font-size: 16px;
    background-color: #181238 !important;
    color: #f2ca83 !important;
    border: 1px solid #181238 !important;
    /* color: white; */
    /* border: none; */
    /* border-radius: 4px; */
    cursor: pointer;
  }
  
  .map-button button div:hover {
    color: white !important;
  }
  .map-button-container {
    display: flex;
    align-items: center;
  }



  @media (min-width: 768px) {
    .map-content {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  
    .map-text {
      flex: 1;
      margin-left: 5rem;
    }
  
    .map-image {
      flex: 1;
    }
  }




/* .featured{
  width: 100%;
  max-width: 1024px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  z-index: 1;
}

.featuredItem{
  position: relative;
  color: white;
  border-radius: 10px;
  overflow: hidden;
  height: 250px;
  flex:1
}

.featuredImg{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featuredTitles{
  position: absolute;
  bottom: 20px;
  left: 20px;
} */