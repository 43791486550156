
.header {
  display: flex;
  text-align: center;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba( 0, 0, 0, 0.2 );
  z-index: 1000;
}

.logoIcon {
  max-width: 110px;
  padding-left: 1vw;
}

.nav-menu {
  display: flex;
  align-items: center;
}

.nav-menu ul {
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
}

.nav-menu ul li {
  margin-right: 20px;
}

.nav-menu ul li a {
  position: relative;
  transition: all 0.3s ease;
}

.nav-menu ul li a::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #007bff;
  transition: width 0.3s;
}

.nav-menu ul li a:hover::after {
  width: 100%;
}

.book-now-button {
  padding: 10px 20px;
  background-color: #1565c0;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.book-now-button:hover {
  background-color: #2196f3;
  transform: scale(1.1);
}

.headerOptions {
  text-decoration: none;
  /* text-shadow: -0.5px 0 slategrey, 0 0.5px slategrey, 0.5px 0 slategrey, 0 -0.5px slategrey; */
  color: white;
  font-weight: 400;
  line-height: 140%;
  font-size: 1rem;
  position: relative;
  transition: all 0.3s ease;
}

.headerOptions:hover {
  color: #007bff;
}

.menu-toggle {
  display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@media only screen and (max-width: 768px) {
  .nav-menu {
    display: none;
    animation: fadeOut 0.5s;
  }

  .menu-toggle {
    /* display: block; */
    cursor: pointer;
    color: white;
    display: flex;
  }

  .menuToggleIcon {
    margin-left: 5vw;
    font-size: 30px;
  }

  .headerOptionsSmallWindow {
    text-decoration: none;
    /* text-shadow: -0.5px 0 slategrey, 0 0.5px slategrey, 0.5px 0 slategrey, 0 -0.5px slategrey; */
    color: black;
    font-weight: 300;
    line-height: 140%;
    font-size: 1rem;
    position: relative;
    transition: all 0.3s ease;
  }
  
  .headerOptionsSmallWindow:hover {
    color: #007bff;
  }

  .menu-toggle i {
    font-size: 1.5rem;
  }

  .nav-menu.active {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: rgba( 255, 255, 255, 1 );
    animation: fadeIn 0.5s forwards;
  }

  .nav-menu.active ul {
    flex-direction: column;
    align-items: center;
    animation: fadeIn 0.5s forwards;
  }

  .nav-menu.active ul li {
    margin: 10px 0;
  }
}