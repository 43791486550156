.amenities-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  text-align: center;
  margin: 20px;
}

.amenities-title {
  color: black;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
}

.amenities-title h2 {
  font-size: 36px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

.amenities-description {
  font-size: 18px;
  color: grey; /* Light green color */
}

h2:after {
  width: 100px;
  height: 2px;
  background: #006400; /* Dark green color */
  margin-left: 10px;
}

.amenities-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.amenity {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  cursor: pointer;
  font-family: "Roboto", sans-serif; /* Use the "Roboto" font */
}

.amenity:hover {
  transform: translateY(-5px);
}

.amenity-icon {
  font-size: 36px;
  color: #333;
}

.amenity-name {
  margin: 15px 0;
  font-size: 18px;
  font-weight: bold;
  color: #000;
  font-family: "Oswald", sans-serif; /* Use the "Roboto" font */
}

.amenity-description {
  font-size: 14px;
  color: #666;
  line-height: 1.4;
  font-style: italic;
  font-family: "Roboto", sans-serif; /* Use the "Roboto" font */
}

@media (max-width: 768px) {
  .amenities-container {
    grid-template-columns: 1fr;
    text-align: center;
  }

  .amenity {
    width: 100%;
  }
}
