@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap");

.about-container {
  width: 90%;
  margin: 0 auto;
  padding: 20px 0;
}

.about-content {
  padding: 20px;
  display: flex;
  flex-direction: column-reverse;
}

.about-text {
  font-family: "Montserrat", sans-serif;
  text-transform: none;
  margin: 20px;
  /* margin-top: 3vw; */
}
.about-details {
  font-weight: 200;
  margin-top: 2vw;
  color: rgb(119, 136, 153);
}

.about-image img {
  max-width: 100%;
  height: auto;
}
.about-heading {
  position: relative;
  display: inline-block;
  font-size: 28px;
  color: #333;
  margin-bottom: 2rem;
}

.about-heading::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  background-color: #0077cc;
  bottom: -10px;
  left: 0;
}

.about-tagline {
  font-family: fantasy;
}

.explore-button {
  text-align: center;
  margin-top: 20px;
}

.explore-button button {
  padding: 12px 24px;
  font-size: 16px;
  background-color: #181238 !important;
  color: #f2ca83 !important;
  border: 1px solid #181238 !important;
  cursor: pointer;
}

.explore-button button:hover {
  color: white !important;
}

@media (min-width: 768px) {
  .about-content {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .about-text {
    flex: 1;
    margin-right: 20px;
  }

  .about-image {
    flex: 1;
  }
}
