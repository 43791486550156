.social-media-links {
  position: fixed;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  background-color: transparent;
}

.social-media-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  margin-bottom: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.social-media-link.instagram {
  background-color: #e4405f;
}

.social-media-link.facebook {
  background-color: #3b5998;
}

.social-media-link.whatsapp {
  background-color: #25d366;
}

.social-media-link svg {
  color: white;
  font-size: 20px;
}

.social-media-link:hover {
  transform: scale(1.1);
}
