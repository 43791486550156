@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap");

.gallery {
  display: flex;
  flex-direction: column;
  --tw-bg-opacity: 1 !important;
  background-color: rgb(242 251 247 / var(--tw-bg-opacity)) !important;
  background-size: cover;
  background-position: center;
  color: #fff;
  padding: 20px;
}

.gallery-title {
  color: #009378;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
}

.gallery-title h2 {
  font-size: 36px;
  font-family: "Oswald", sans-serif;
  font-weight: 700;
}

.gallery-description {
  font-size: 18px;
  color: #08a05eef; /* Light green color */
}

h2:after {
  width: 100px;
  height: 2px;
  background: #006400; /* Dark green color */
  margin-left: 10px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.image-grid {
  display: flex;
  justify-content: center;
  gap: 5px;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.image-item {
  width: 330px; /* Adjust this value as needed */
  height: 250px; /* Adjust this value as needed */
  overflow: hidden;
  cursor: pointer;
  position: relative;
}

.image-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-item:hover img {
  transform: scale(1.1);
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.overlay img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
}
